var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "archive pa-0 ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { staticClass: "pb-0 mb-0 pt-0", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pb-0 mb-0 px-0 mt-0 pt-2" },
            [
              _c(
                "div",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-toolbar-title", { staticClass: "ml-2 title" }, [
                        _vm._v(" Analytics "),
                      ]),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" },
                      }),
                      _c(
                        "v-col",
                        { staticClass: "mr-auto", attrs: { cols: "auto" } },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "Selected Resource",
                              items: _vm.resources,
                              "item-text": "title",
                              "item-value": "id",
                              "menu-props": { offsetY: true },
                              outlined: "",
                              dense: "",
                              clearable: "",
                              "hide-details": "auto",
                            },
                            model: {
                              value: _vm.selectedResourceId,
                              callback: function ($$v) {
                                _vm.selectedResourceId = $$v
                              },
                              expression: "selectedResourceId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c("MetabaseEmbed", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentPath,
                    expression: "currentPath",
                  },
                ],
                attrs: { path: _vm.currentPath, fitHeight: _vm.fitHeight },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }